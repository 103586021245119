// Contact Styles 

.contact-form--1 {
    label {
        display: block;
        margin-bottom: 0;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        &:focus {
            border-color: $theme-color;
        }
    }
    textarea {
        height: 120px;
    }
}


/*==========================
    Contact Address 
============================*/

.rn-address {
    margin-top: 30px;
    border: 1px solid #ebebeb;
    padding: 40px;
    border-radius: 10px;
    padding-top: 54px;
    background: #fff;
    padding-bottom: 50px;
    .icon {
        border: 1px solid #ebebeb;
        width: 78px;
        height: 78px;
        color: $theme-color;
        line-height: 73px;
        text-align: center;
        border-radius: 100%;
        background: #ffffff;
        font-size: 28px;
        position: absolute;
        top: -13px;
        left: 47px;
        @extend %transition;
    }
    .inner {
        h4 {
            &.title {
                font-size: 18px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            color: rgba(29, 29, 36, 0.75);
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: rgba(29, 29, 36, 0.75);
                @extend %transition;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &:hover {
        .icon {
            background: $theme-color;
            border-color: $theme-color;
            color: #fff;
        }
    }
}

input,
textarea {
    @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus ,
.blog-comment-form .inner .rnform-group textarea:focus {
    border-color: $theme-color ;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 20px;
}





.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: $theme-color;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -10px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px $theme-color;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
